import type { ReactNode } from "react";
import { Shield } from "~/components/Shield";

export function DefaultLayout({ children }: { children: ReactNode }) {
  return (
    <div className="bg-primary h-screen w-screen flex items-center justify-center text-white/90 relative z-0">
      <div
        className="h-screen w-screen absolute -z-10 left-0 right-0 bottom-0 top-0 overflow-hidden"
        style={{
          background:
            "linear-gradient(90deg,#0A0094 0,#0A0094 calc(50% + 280px),#030083 calc(50% + 280px),#030083)",
        }}
      >
        <Shield />
      </div>
      <div className="bg-primaryDark rounded-lg shadow-xl p-16 relative">
        {children}
      </div>
    </div>
  );
}
